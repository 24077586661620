import { CompanyType } from "types/company";
import { UserType } from "types/user";
import { JobVacancyResponse } from "utils/api/data-contracts";

export type initialStoreType = {
  sideMenu: boolean,
  company: CompanyType | null,
  user: UserType | null,
  jobDetail: JobVacancyResponse,
  applicantDetail: object | null,
  landingPage_fetched: boolean,
};

const initialStore: initialStoreType = {
  sideMenu: false,
  company: null,
  user: null,
  jobDetail: null,
  applicantDetail: null,
  landingPage_fetched: false,
};

export type JobListStoreType = {
  modalCreateJob: boolean,
  preview: JobVacancyResponse,
  modalCancel: boolean,
  jobList: Array<JobVacancyResponse>
}

export const jobListStore = {
  modalCreateJob: false,
  preview: null,
  modalCancel: false,
  jobList: null,
};

export default initialStore;
